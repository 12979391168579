import React from "react"
import instagramData from "../staticInstagramData"
// import PropTypes from "prop-types"
// import { StaticQuery, graphql } from "gatsby"

// const staticInstagramData

// const query = graphql`
//   query {
//     allInstaNode(filter: { id: { ne: "ByceN-Zn66Z" } }) {
//       edges {
//         node {
//           id
//           likes
//           comments
//           mediaType
//           preview
//           original
//           timestamp
//           caption
//           localFile {
//             childImageSharp {
//               fixed(width: 150, height: 150) {
//                 ...GatsbyImageSharpFixed
//               }
//             }
//           }
//           # Only available with the public api scraper
//           thumbnails {
//             src
//             config_width
//             config_height
//           }
//           dimensions {
//             height
//             width
//           }
//         }
//       }
//     }
//   }
// `

const { data } = instagramData

const InstagramGallery = props => {
  // const
  // <StaticQuery
  //   query={query}
  //   render={data =>
  return data.allInstaNode.edges.map(({ node }) => {
    const { src, config_width, config_height } = node.thumbnails[2]
    return (
      <a href={node.original} target="_blank" key={src}>
        <img src={src} width={config_width} height={config_height} />
      </a>
    )
  })
  //   }
  // />
}

InstagramGallery.propTypes = {}

export default InstagramGallery
