export default () => ({
  /* TODO: get rid of importants */
  "&:before": {
    opacity: "0.1 !important",
    backgroundRepeat: "repeat !important",
    backgroundSize: "auto !important",
  },

  "&:after": {
    backgroundColor: "white",
    backgroundImage: "none !important",
  },
})
