const size = 50

export default ({ theme }) => ({
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  width: `${size}px`,
  height: `${size}px`,
  backgroundColor: theme.colors.black,
  color: theme.colors.white,
  borderRadius: `${size / 2}px`,

  "& svg": {
    width: "80%",
    height: "80%",
  },
})
