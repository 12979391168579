export default ({ theme: { breakpoints, photo } }) => ({
  height: "100%",
  padding: `${photo.contentPadding}px`,
  borderRadius: "0",
  boxShadow: "3px 3px 5px 0px grey",

  [breakpoints.smUp]: {
    margin: `${photo.contentPadding}px`,
  },

  "& .card-img": {
    borderRadius: "0",
  },

  "& .card-title": {
    fontSize: "1.5em",
    fontWeight: "bold",
  },

  "& .card-body": {
    textAlign: "center",
  },
})
