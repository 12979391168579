import React from "react"
// import PropTypes from "prop-types"
import { FormGroup, Label, Input, FormFeedback } from "reactstrap"

const TextInput = ({ field, label, form, error, ...props }) => (
  <FormGroup>
    <Label for={field.name}>{label}</Label>
    <Input íd={field.name} {...props} {...field} invalid={Boolean(error)} />
    <FormFeedback valid={!error}>{error}</FormFeedback>
  </FormGroup>
)

TextInput.defaultProps = {
  type: "text",
}

export default TextInput
