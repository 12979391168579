import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import ImageGallery from "@browniebroke/gatsby-image-gallery"
import "@browniebroke/gatsby-image-gallery/dist/style.css"

const Gallery = () => {
  const { images } = useStaticQuery(graphql`
    query ImagesForGallery {
      images: allFile(
        filter: { relativeDirectory: { eq: "gallery" } }
        sort: { fields: name }
      ) {
        edges {
          node {
            id
            thumb: childImageSharp {
              fluid(maxWidth: 270, maxHeight: 270) {
                ...GatsbyImageSharpFluid
              }
            }
            full: childImageSharp {
              fluid(
                maxWidth: 1024
                quality: 85
                srcSetBreakpoints: [576, 768, 992, 1200]
              ) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)
  const fullSize = images.edges.map(edge => edge.node.full.fluid.src)
  const thumbs = images.edges.map(edge => edge.node.thumb.fluid)

  return <ImageGallery images={fullSize} thumbs={thumbs} />
}

export default Gallery
