import React from "react"
import PropTypes from "prop-types"
import { FelaComponent } from "react-fela";

import style from "./Title.style"

const Title = ({ title: TitleElement, subTitle, big }) => (
  <FelaComponent style={style} big={big}>
    {({ className }) => (
      <React.Fragment>
        <TitleElement className={className} />
        {subTitle && <h3 className="sub-title">{subTitle}</h3>}
      </React.Fragment>
    )}
  </FelaComponent>
)

Title.propTypes = {
  big: PropTypes.bool,
  subTitle: PropTypes.node,
}
Title.defaultProps = {
  big: false,
  subTitle: null,
}

export default Title
