import React from "react"
import { Row, Col } from "reactstrap"
import ScrollToTop from "react-scroll-up"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import { Title, BgPatternSection, SectionContent } from "../components/sections"
import { BridesMaid, BestMan } from "../components/profiles"
import Info from "../components/info"
import Timeline from "../components/timeline"
import LocationAndTime from "../components/LocationAndTime"
import VenueMap from "../components/VenueMap"
import ContactForm from "../components/ContactForm"
import ScrollerUp from "../components/ScrollerUp"
import Gallery from "../components/Gallery"

import titleGods from "../images/chapter-titles/title-gods.svg"
import titleWhenWhere from "../images/chapter-titles/title-when-where.svg"
import titleInfo from "../images/chapter-titles/title-info.svg"
import titleTimeline from "../images/chapter-titles/title-timeline.svg"
import titleFriends from "../images/chapter-titles/title-friends.svg"
import titleContact from "../images/chapter-titles/title-contact.svg"
import Playlist from "../components/Playlist"
import TattooImage from "../components/TattooImage"
import InstagramGallery from "../components/InstagramGallery"

const IndexPage = () => (
  <Layout>
    <SEO
      title="Anet &amp; Luky ...prostě jakoby svatba"
      keywords={[`svatba`, `wedding`, `anet`, `luky`, `prostejakoby`]}
    />
    <ScrollToTop
      showUnder={300}
      style={{ ...ScrollToTop.defaultProps.style, zIndex: 999 }}
    >
      <ScrollerUp />
    </ScrollToTop>

    <BgPatternSection id="playlists">
      {/* <Title title={titleGods} big> */}
      <h1 className="text-center">Svatební playlisty</h1>
      {/* </Title> */}
      <Row>
        <Col xs={12} sm={6}>
          <Playlist id="54VJXOqhO2FlAchoWGK6mv" owner="Anet" />
        </Col>
        <Col xs={12} sm={6} className="mt-2 mt-sm-0">
          <Playlist id="086j9wPBlWMuB0GwgAOm58" owner="Luky" />
        </Col>
      </Row>
    </BgPatternSection>

    <SectionContent className="py-0" fullWidth>
      <TattooImage />
    </SectionContent>

    <BgPatternSection id="instagram">
      <h1 className="text-center">Instantní příběh</h1>
      <InstagramGallery />
    </BgPatternSection>

    <SectionContent id="photostory">
      <h1 className="text-center">Fotopříběh</h1>
      <Gallery />
    </SectionContent>

    {/*
    <SectionContent fullWidth noPadding>
      <VenueMap />
    </SectionContent>

    <SectionContent id="lineup">
      <Title title={titleTimeline}>...tohle všechno tě čeká</Title>
      <Timeline />
    </SectionContent>

    <BgPatternSection id="svedci">
      <Title title={titleFriends}>...v nouzi poznáš přítele</Title>
      <Row>
        <Col xs={12} sm={6}>
          <BridesMaid />
        </Col>
        <Col xs={12} sm={6} className="mt-2 mt-sm-0">
          <BestMan />
        </Col>
      </Row>
    </BgPatternSection>

    <SectionContent id="info">
      <Title title={titleInfo} subTitle="aneb další nálož důležitých informací">
        Kdo je připraven, není zaskočen
      </Title>
      <Info />
    </SectionContent>

    <BgPatternSection id="kontakt">
      <Title title={titleContact}>Kontaktuj nás</Title>
      <ContactForm />
    </BgPatternSection> */}
  </Layout>
)

export default IndexPage
