import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-background-image"
import { FelaComponent } from "react-fela"
import SectionContent from "./SectionContent"

import style from "./BgPatternSection.style"

const BgPatternSection = ({ children, ...props }) => (
  <StaticQuery
    query={graphql`
      query {
        placeholderImage: file(
          relativePath: { eq: "backgrounds/pattern-v2.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <FelaComponent style={style}>
        {({ className }) => (
          <Img
            className={`${className} d-flex flex-column justify-content-center align-items-center`}
            classId="pattern"
            fluid={data.placeholderImage.childImageSharp.fluid}
          >
            <SectionContent {...props}>{children}</SectionContent>
          </Img>
        )}
      </FelaComponent>
    )}
  />
)
export default BgPatternSection
