import React from "react"
import PropTypes from "prop-types"
import { FelaComponent } from "react-fela"

import style from "./SectionContent.style"

const SectionContent = ({ children, id, fullWidth, noPadding, className }) => (
  <FelaComponent fullWidth={fullWidth} noPadding={noPadding} style={style}>
    {({ className: secondClassName }) => (
      <div className={`${className} ${secondClassName}`} id={id}>
        {children}
      </div>
    )}
  </FelaComponent>
)

SectionContent.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  noPadding: PropTypes.bool,
}
SectionContent.defaultProps = {
  id: null,
  className: "",
  fullWidth: false,
  noPadding: false,
}

export default SectionContent
