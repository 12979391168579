export default ({ theme: { breakpoints, timeline, colors }, rightSide }) => {
  const halfItemSize = timeline.itemSize / 2
  const leadingLine = `${timeline.leadingLineWidth}px solid ${colors.black}`
  const itemPaddingTop = halfItemSize

  return {
    "& .content": {
      position: "relative",
      padding: `${itemPaddingTop}rem 0 ${itemPaddingTop / 2}rem 0`,
      paddingRight: `${halfItemSize + timeline.itemLabelMargin}rem`,
      borderRight: leadingLine,
      textAlign: "right",

      [breakpoints.smUp]: rightSide && {
        left: `${-1 * timeline.leadingLineWidth}px`,
        paddingRight: 0,
        paddingLeft: `${halfItemSize + timeline.itemLabelMargin}rem`,
        borderRight: 0,
        borderLeft: leadingLine,
        textAlign: "left",
      },
    },

    "& .icon": {
      position: "absolute",
      top: `${itemPaddingTop -
        (timeline.itemSize - timeline.itemTimeFontSize) / 2}rem`,
      right: `${-1 * halfItemSize}rem`,
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      width: `${timeline.itemSize}rem`,
      height: `${timeline.itemSize}rem`,
      borderRadius: `${halfItemSize}rem`,
      backgroundColor: "#000000",
      zIndex: "2",

      [breakpoints.smUp]: rightSide && {
        left: `${-0.5 * timeline.itemSize}rem`,
        right: "auto",
      },
    },

    "& .label-time": {
      marginBottom: "0.2rem",
      fontWeight: "bold",
      fontSize: `${timeline.itemTimeFontSize}rem`,
    },

    "& .label-title": {
      marginBottom: "0.2rem",
      fontWeight: "bold",
      textTransform: "uppercase",
      fontSize: `${timeline.itemTitleFontSize}rem`,
      lineHeight: `${timeline.itemTitleFontSize * 1.4}rem`,
    },

    "& .label-description": {
      fontStyle: "italic",
    },

    "&:first-of-type": {
      marginTop: `${timeline.itemSize}rem`,

      "& .content": {
        paddingTop: 0,
      },

      "& .icon": {
        top: `${0 - (timeline.itemSize - timeline.itemTimeFontSize) / 2}rem`,
      },
    },

    "&:last-of-type": {
      marginBottom: `${timeline.itemSize}rem`,

      "& .content": {
        paddingBottom: 0,
        paddingTop: `${(timeline.itemSize - timeline.itemTimeFontSize) / 2}rem`,
        borderLeft: 0,
        borderRight: 0,
      },

      "& .icon": {
        top: 0,
      },
    },
  }
}
