import React from "react"
import PropTypes from "prop-types"
import { Row, Col } from "reactstrap"
import { FelaComponent } from "react-fela"

import style from "./TimelineItem.style"

const TimelineItem = ({ icon, time, title, description, rightSide }) => {
  const timeList = Array.isArray(time) ? time : [time]
  const titleList = Array.isArray(title) ? title : [title]
  const descriptionList = Array.isArray(description)
    ? description
    : [description]

  return (
    <FelaComponent style={style} rightSide={rightSide}>
      {({ className }) => (
        <Row className={className}>
          <Col
            xs={{ size: 10 }}
            sm={{ size: 6, offset: rightSide ? 6 : 0 }}
            className="content"
          >
            <span className="icon">{icon}</span>
            {timeList.map((timeItem, i) => (
              <span className="label" key={`item-label-${i}`}>
                <h4 className="label-time">{timeItem}</h4>
                <h5 className="label-title">{titleList[i]}</h5>
                <p className="label-description">
                  {descriptionList[i]}
                </p>
              </span>
            ))}
          </Col>
        </Row>
      )}
    </FelaComponent>
  )
}

const nodeOrListOfNodesType = PropTypes.oneOfType([
  PropTypes.node,
  PropTypes.arrayOf(PropTypes.node),
])

TimelineItem.propTypes = {
  icon: PropTypes.node,
  time: nodeOrListOfNodesType,
  title: nodeOrListOfNodesType,
  description: nodeOrListOfNodesType,
  rightSide: PropTypes.bool,
}

TimelineItem.defaultProps = {
  icon: null,
  time: null,
  title: null,
  description: null,
  rightSide: false,
}

export default TimelineItem
