import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import { Card, CardImg, CardTitle, CardText, CardBody } from "reactstrap"
import { FelaComponent } from "react-fela"
import { combineRules } from "fela"

import photoContentStyle from "./PhotoContent.style"

const PhotoContent = ({
  image,
  imgWidth,
  imgHeight,
  title,
  style,
  description,
}) => (
  <FelaComponent style={combineRules(photoContentStyle, style)}>
    {({ className }) => (
      <Card className={className}>
        {typeof image === "string" ? (
          <CardImg
            width={imgWidth}
            height={imgHeight}
            src={image}
            alt={title}
          />
        ) : (
          <Img fluid={image} width={imgWidth} height={imgHeight} />
        )}

        <CardBody>
          <CardTitle>{title}</CardTitle>
          <CardText tag="div">{description}</CardText>
        </CardBody>
      </Card>
    )}
  </FelaComponent>
)

PhotoContent.propTypes = {
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  imgWidth: PropTypes.number,
  imgHeight: PropTypes.number,
  style: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.node,
}

PhotoContent.defaultProps = {
  image: "https://via.placeholder.com/150",
}

export default PhotoContent
