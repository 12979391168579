import React from 'react';
import { FelaComponent } from 'react-fela';
import ArrowUpIcon from '../images/icons/arrow-up.svg';

import style from "./ScrollerUp.style"

const ScrollerUp = props => (
    <FelaComponent style={style} as="span"><ArrowUpIcon /></FelaComponent>
);

export default ScrollerUp;