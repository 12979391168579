export default ({ theme: { breakpoints }, big }) => ({
  height: big ? "110%" : "auto",
  width: "130%",
  marginLeft: "-15%",
  marginBottom: "20px",

  [breakpoints.smUp]: {
    width: "initial",
    marginLeft: "initial",
  },

  "& + .sub-title": {
    fontSize: "1rem",
    fontWeight: "bold",
    textAlign: "center",
  },
})
