import React, { useState } from "react"
import PropTypes from "prop-types"
import { connect as connectFela } from "react-fela"
import * as playlistStyles from "./Playlist.style"

const LOADING = true
const LOADED = false

const Playlist = ({ id, styles, owner }) => {
  const [state, setState] = useState(LOADING)
  return (
    <div className={styles.playlist}>
      <h2>{owner}</h2>
      {state === LOADING && <h4 className="mt-2">Načítám playlist....</h4>}
      <iframe
        src={`https://open.spotify.com/embed/playlist/${id}`}
        width="300"
        height="380"
        frameBorder="0"
        allowtransparency="true"
        allow="encrypted-media"
        onLoad={() => setState(LOADED)}
      />
    </div>
  )
}

Playlist.propTypes = {
  styles: PropTypes.shape({
    playlist: PropTypes.string,
  }).isRequired,
  id: PropTypes.string.isRequired,
  owner: PropTypes.string.isRequired,
}

export default connectFela(playlistStyles)(Playlist)
