import React, { useState } from "react"
import { Button, FormGroup, Row, Col, Spinner } from "reactstrap"
import { Field, Formik } from "formik"
import * as yup from "yup"
import { FelaComponent } from "react-fela"
import RadioOption from "./fields/RadioOption"
import TextInput from "./fields/TextInput"

import { buttonStyle } from "./ContactForm.style"

const AttendAnswers = {
  YES: "Ano",
  MAYBE: "Maybe",
  NO: "Ne",
}

const MAYBE_OPTION_MESSAGE_TIMEOUT = 3000

const maybeOptionStyle = {
  fontWeight: "bold",
  display: "inline-block",
  marginLeft: 15,
}

const contactSchema = yup.object().shape({
  name: yup.string().required("Jméno musí být vyplněno"),
  email: yup
    .string()
    .nullable()
    .email("Nesprávný formát email")
    .required("Email musí být vyplněn"),
  attend: yup
    .string()
    .nullable()
    .required("Nezapomeň zaškrtnout jestli dorazíš"),
})

const ContactForm = props => {
  const [maybeOptionMessage, setMaybeOptionMessage] = useState(null)
  return (
    <Formik
      initialValues={{ name: "", email: "", attend: null }}
      onSubmit={(values, actions) => {
        window
          .fetch("/api/send-form", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(values),
          })
          .then(() => {
            actions.setStatus({ submitSucceeded: true })
          })
          .catch(() => {})
          .finally(() => {
            actions.setSubmitting(false)
          })
      }}
      validationSchema={contactSchema}
      render={({
        values,
        errors,
        isValid,
        isSubmitting,
        touched,
        handleSubmit,
        status,
        setStatus,
        resetForm,
      }) => {
        const reset = () => {
          setStatus()
          resetForm()
        }

        return status && status.submitSucceeded ? (
          <div className="text-center">
            <h3 className="text-uppercase mb-5">
              Tvoje odpověď i případný vzkaz nám byli v pořádku odeslány
            </h3>
            <FelaComponent style={buttonStyle}>
              {({ className }) => (
                <Button onClick={reset} className={`${className} mb-5`}>
                  Potřebuješ znovu odeslat formulář?
                </Button>
              )}
            </FelaComponent>
          </div>
        ) : (
          <form onSubmit={handleSubmit}>
            <Row>
              <Col sm={6}>
                <Field
                  name="name"
                  label="Jméno"
                  placeholder="Tvoje jméno"
                  error={touched.name && errors.name}
                  disabled={isSubmitting}
                  component={TextInput}
                />
              </Col>
              <Col sm={6}>
                <Field
                  name="email"
                  type="email"
                  label="Email"
                  placeholder="Tvůj email"
                  error={touched.email && errors.email}
                  disabled={isSubmitting}
                  component={TextInput}
                />
              </Col>
            </Row>
            <FormGroup>
              <span>Dorazíš?</span>
              <Field
                component={RadioOption}
                name="attend"
                id={AttendAnswers.YES}
                label="Jasně!"
                disabled={isSubmitting}
              />
              <RadioOption
                id={AttendAnswers.MAYBE}
                label="Možná (ani to nezkoušej)"
                error={
                  maybeOptionMessage && (
                    <div style={maybeOptionStyle}>{maybeOptionMessage}</div>
                  )
                }
                field={{
                  name: "attend",
                  value: "unselectable",
                  onChange: () => {
                    setMaybeOptionMessage(
                      `Dobrej pokus${
                        values.attend !== null
                          ? ""
                          : ", ale teď nám řekni jestli dorazíš"
                      } :)`
                    )
                    setTimeout(() => {
                      setMaybeOptionMessage(null)
                    }, MAYBE_OPTION_MESSAGE_TIMEOUT)
                  },
                }}
              />
              <Field
                component={RadioOption}
                name="attend"
                id={AttendAnswers.NO}
                label="Už něco mám"
                error={touched.attend && errors.attend}
                disabled={isSubmitting}
              />
            </FormGroup>
            {values.attend && (
              <Field
                type="textarea"
                name="message"
                label="Máš ještě něco na srdci? Hrň to do nás..."
                error={touched.message && errors.message}
                disabled={isSubmitting}
                component={TextInput}
              />
            )}
            <FelaComponent style={buttonStyle}>
              {({ className }) => (
                <Button
                  type="submit"
                  className={className}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <React.Fragment>
                      Posílám <Spinner color="light" size="sm" />
                    </React.Fragment>
                  ) : (
                    "Pošli posli"
                  )}
                </Button>
              )}
            </FelaComponent>
          </form>
        )
      }}
    />
  )
}

export default ContactForm
