import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-background-image"
import addYears from "date-fns/addYears"
import addMonths from "date-fns/addMonths"
import differenceInYears from "date-fns/differenceInYears"
import differenceInMonths from "date-fns/differenceInMonths"
import differenceInDays from "date-fns/differenceInDays"

const WEDDING_DATE = new Date(2019, 5, 8)

const Unit = {
  DAY: "day",
  MONTH: "month",
  YEAR: "year",
}

const unitNames = {
  [Unit.DAY]: {
    one: "den",
    few: "dny",
    many: "dní",
  },
  [Unit.MONTH]: {
    one: "měsíc",
    few: "měsíce",
    many: "měsíců",
  },
  [Unit.YEAR]: {
    one: "rok",
    few: "roky",
    many: "roků",
  },
}

const getFormattedDateUnit = (count, unit) => {
  const units = unitNames[unit]
  switch (count) {
    case 1:
      return <React.Fragment key={`${unit}-one`}>{count}&nbsp;{units.one}</React.Fragment>
    case 2:
    case 3:
    case 4:
      return <React.Fragment key={`${unit}-few`}>{count}&nbsp;{units.few}</React.Fragment>
    default:
      return <React.Fragment key={`${unit}-many`}>{count}&nbsp;{units.many}</React.Fragment>
  }
}

const TattoImage = ({ children }) => {
  const today = new Date()

  const diffYears = differenceInYears(today, WEDDING_DATE)
  const diffMonths = differenceInMonths(
    today,
    addYears(WEDDING_DATE, diffYears)
  )
  const diffDays = differenceInDays(
    today,
    addMonths(WEDDING_DATE, diffYears * 12 + diffMonths)
  )

  const duration = [];

  if (diffDays > 0) {
    duration.push(getFormattedDateUnit(diffDays, Unit.DAY))
  }
  if (diffMonths > 0) {
    duration.push(getFormattedDateUnit(diffMonths, Unit.MONTH))
  }
  if (diffYears > 0) {
    duration.push(getFormattedDateUnit(diffYears, Unit.YEAR))
  }

  if (duration.length === 2) {
    duration.splice(1, 0, <React.Fragment key="joiner"> a </React.Fragment>)
  } else if (duration.length === 3) {
    duration.splice(1, 0, <React.Fragment key="space"> </React.Fragment>)
    duration.splice(3, 0, <React.Fragment key="joiner"> a </React.Fragment>)
  }

  return (
    <StaticQuery
      query={graphql`
        query {
          placeholderImage: file(relativePath: { eq: "wedding-tattoo.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 2048) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          site {
            siteMetadata {
              date
            }
          }
        }
      `}
      render={data => (
        <Img
          className={`d-flex flex-column justify-content-center align-items-center`}
          fluid={data.placeholderImage.childImageSharp.fluid}
          style={{ minHeight: 500 }}
        >
          <h3 className="text-light text-uppercase">Už jsme svoji</h3>
          <h2 className="text-light display-3 text-center">
            {duration}
          </h2>
        </Img>
      )}
    />
  )
}

TattoImage.propTypes = {
  date: PropTypes.string,
}

export default TattoImage
