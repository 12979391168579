export default ({ theme, fullWidth, noPadding }) => ({
  margin: "0 auto",
  padding: noPadding ? "0" : "3rem 1.0875rem",
  paddingLeft: fullWidth ? 0 : null,
  paddingRight: fullWidth ? 0 : null,
  maxWidth: fullWidth ? "100%" : `${theme.sections.maxWidth}px`,
  width: "100%",
  overflow: "hidden",
  boxSizing: "content-box",
})
