import React from "react"
import PropTypes from "prop-types"

import PhotoContent from "../PhotoContent"
import style from "./PersonProfile.style"

const PersonProfile = ({ image, title, description }) => (
  <PhotoContent
    style={style}
    image={image}
    title={title}
    description={description}
  />
)

PersonProfile.propTypes = {
  image: PropTypes.object.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
}

PersonProfile.defaultProps = {
  title: "",
}

export default PersonProfile
